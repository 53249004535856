import momentTimezone from 'moment-timezone';
import { endOfToday, startOfToday, subDays } from 'date-fns';
import { getLanguage, t } from './ReactSwitchLangWrapper';
import Timezones from '../content/Timezones.json';
import { validateDateTime } from './Validation';
import { DIRECTION, PAYMENT_STATUS, PAYMENT_TYPE, PAYOUT_STATUS, REPORTS, TIMEZONE_CODES, TRANSACTION_TYPE } from './Constants';
import { moveFocusTo, setScreenReaderAlert } from './Accessibility';
import { getPartnerName } from './Helpers';
import { getIntegrationPlatform } from './IntegrationPlatform';
import { formatPhoneNumber } from './Format';
import { events, logAmpEvent } from './Amplitude';
import { isLoadhubUserGroup } from './UserGroup';

export const validPartnerPredicate = (partner) => partner.Status === 'A' || partner.Status === 'D';

export const getTimezoneFullName = (timezoneCode) => {
  const lang = getLanguage();
  const timezone = Timezones.find((tz) => tz.Code === timezoneCode);
  if (!timezone) return undefined;
  return lang === 'fr' ? timezone.French : timezone.English;
};

export const parseDateTimeToString = (dateTime) => {
  if (!(dateTime instanceof Date)) return '';
  const month = `${dateTime.getMonth() + 1}`.padStart(2, '0');
  const day = `${dateTime.getDate()}`.padStart(2, '0');
  const year = dateTime.getFullYear();
  const hour = `${dateTime.getHours()}`.padStart(2, '0');
  const minute = `${dateTime.getMinutes()}`.padStart(2, '0');

  return `${[year, month, day].join('-')} ${hour}:${minute}`;
};

export const parseStringToDateTime = (dateTimeStr) => {
  // format: 'YYYY-MM-DD HH:mm'
  if (validateDateTime(dateTimeStr)) return null;

  const parts = dateTimeStr.split(' ');
  const dateParts = parts[0].split('-');
  const timeParts = parts[1].split(':');

  const d = new Date(
    parseInt(dateParts[0], 10), // year
    parseInt(dateParts[1], 10) - 1, // month
    parseInt(dateParts[2], 10), // day
    parseInt(timeParts[0], 10), // hour
    parseInt(timeParts[1], 10) // minute
  );

  return d;
};

export const parseStringToUTCDateTime = (dateTimeStr) => {
  // format: 'YYYY-MM-DD HH:mm'
  if (validateDateTime(dateTimeStr)) return null;

  const parts = dateTimeStr.split(' ');
  const dateParts = parts[0].split('-');
  const timeParts = parts[1].split(':');

  const d = new Date(Date.UTC(
    parseInt(dateParts[0], 10), // year
    parseInt(dateParts[1], 10) - 1, // month
    parseInt(dateParts[2], 10), // day
    parseInt(timeParts[0], 10), // hour
    parseInt(timeParts[1], 10) // minute
  ));

  return d;
};

export function parseDateToUTCString(date) {
  if (!(date instanceof Date)) return '';
  const month = `${date.getUTCMonth() + 1}`.padStart(2, '0');
  const day = `${date.getUTCDate()}`.padStart(2, '0');
  const year = date.getUTCFullYear();

  return [year, month, day].join('-');
}

export const parseDateTimeToUTCString = (dateTime, includeSeconds) => {
  if (!(dateTime instanceof Date)) return '';
  const month = `${dateTime.getUTCMonth() + 1}`.padStart(2, '0');
  const day = `${dateTime.getUTCDate()}`.padStart(2, '0');
  const year = dateTime.getUTCFullYear();
  const hour = `${dateTime.getUTCHours()}`.padStart(2, '0');
  const minute = `${dateTime.getUTCMinutes()}`.padStart(2, '0');
  const seconds = `${dateTime.getUTCSeconds()}`.padStart(2, '0');

  return `${[year, month, day].join('-')} ${hour}:${minute}${includeSeconds ? `:${seconds}` : ''}`;
};

export const convertUTCToTimezoneDateTimeString = (
  dateTimeUTC,
  timezoneCode,
  includeSeconds = false
) => {
  if (!dateTimeUTC) return '';

  const timezoneOffsetHours = TIMEZONE_CODES[timezoneCode];
  const timezoneOffsetMinutes = timezoneOffsetHours * 60;
  const date = new Date(dateTimeUTC);
  date.setMinutes(date.getMinutes() - timezoneOffsetMinutes);
  const formattedDate = parseDateTimeToUTCString(date, includeSeconds);
  return formattedDate;
};

export const convertUTCToTimezoneDateString = (dateTimeUTC, timezoneCode) => {
  if (!dateTimeUTC) return '';

  const timezoneOffsetHours = TIMEZONE_CODES[timezoneCode];
  const timezoneOffsetMinutes = timezoneOffsetHours * 60;
  const date = new Date(dateTimeUTC);
  date.setMinutes(date.getMinutes() - timezoneOffsetMinutes);
  const formattedDate = parseDateToUTCString(date);
  return formattedDate;
};

export const formatReportAmount = (amount) => {
  const lang = getLanguage();
  const formatter = new Intl.NumberFormat(`${lang}-CA`, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formatter.format(amount);
};

export const filterAmount = (rowVal, filtersVal) => (getLanguage() === 'en' ?
  rowVal.replace(/[,]/g, '').startsWith(filtersVal.replace(/[,]/g, '')) :
  rowVal.replace(/[\s]/g, '').startsWith(filtersVal.replace(/[\s]/g, '')));

export const inputStopPropagation = (event) => {
  if (['ArrowLeft', 'ArrowRight'].includes(event.key)) {
    event.stopPropagation();
  }
};

export const selectStopPropagation = (event) => {
  if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(event.key)) {
    event.stopPropagation();
  }
};

export const optionDropdownStopPropagation = (showDropdown, event) => {
  if (showDropdown && !event.shiftKey) event.stopPropagation();
};

export const optionDropdownLastButtonStopPropagation = (lastButton, toggleDropdown, event) => {
  if (lastButton) { // last button in dropdown
    if (event.shiftKey) {
      event.stopPropagation();
      return true;
    }
    if (event.key === 'Tab') toggleDropdown(false);
    return true;
  }
  return false;
};

export const moveFocusToRefundForm = (showRefundForm) => {
  if (showRefundForm) {
    setScreenReaderAlert(t('RequestRefund_FormOpen_ScreenReaderAlert'));
    moveFocusTo('request-refund');
  }
};

// moment timezone's best guess of the users timezone, eg. 'pst'
let userTimezone;
export const getUserTimezone = () => {
  if (!userTimezone) {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    const timezoneAbbr = momentTimezone.tz(timeZone).zoneAbbr();
    userTimezone = TIMEZONE_CODES[timezoneAbbr] ? timezoneAbbr : Timezones[0].Code;
  }
  return userTimezone;
};

const getPaymentStatusLangValue = (status) => {
  switch (status) {
    case PAYMENT_STATUS.PAID:
      return t('Reports_Payments_Status_Paid');
    case PAYMENT_STATUS.STAGED:
      return t('Reports_Payments_Status_Staged');
    case PAYMENT_STATUS.EXCEPTION:
      return t('Reports_Payments_Status_Exception');
    case PAYMENT_STATUS.FAILED:
      return t('Reports_Payments_Status_Failed');
    case PAYMENT_STATUS.DECLINED:
      return t('Reports_Payments_Status_Declined');
    case PAYMENT_STATUS.CANCELLED:
      return t('Reports_Payments_Status_Cancelled');
    case PAYMENT_STATUS.PROCESSING:
      return t('Reports_Payments_Status_Processing');
    case PAYMENT_STATUS.EXPIRED:
      return t('Reports_Payments_Status_Expired');
    case PAYMENT_STATUS.REFUNDED:
      return t('Reports_Payments_Status_Refunded');
    case PAYMENT_STATUS.REFUND_PENDING:
      return t('Reports_Payments_Status_RefundPending');
    case PAYMENT_STATUS.REFUND_MULTIPLE:
      return t('Reports_Payments_Status_RefundMultiple');
    case PAYMENT_STATUS.REFUND_CANCELLED:
      return t('Reports_Payments_Status_RefundCancelled');
    case PAYMENT_STATUS.TEST_MODE:
      return t('Reports_Payments_Status_TestMode');
    case PAYMENT_STATUS.UNKNOWN:
    default:
      return t('Reports_Payments_Status_Unknown');
  }
};

export const getPayoutStatusLangValue = (status) => {
  switch (status) {
    case PAYOUT_STATUS.NET_ZERO:
      return t('Reports_Payout_Status_NetZero');
    case PAYOUT_STATUS.PENDING:
      return t('Reports_Payout_Status_Pending');
    case PAYOUT_STATUS.SENT:
      return t('Reports_Payout_Status_Sent');
    case PAYOUT_STATUS.FAILED:
      return t('Reports_Payout_Status_Failed');
    case PAYOUT_STATUS.REJECTED:
      return t('Reports_Payout_Status_Rejected');
    case PAYOUT_STATUS.COMPLETED:
      return t('Reports_Payout_Status_Completed');
    case PAYOUT_STATUS.UNKNOWN:
    default:
      return t('Reports_Payout_Status_Unknown');
  }
};

const getTransactionTypeLangValue = (type) => {
  switch (type) {
    case TRANSACTION_TYPE.PAD_CREDIT:
      return t('Reports_Transaction_Type_PADCredit');
    case TRANSACTION_TYPE.PURCHASE:
      return t('Reports_Transaction_Type_Purchase');
    case TRANSACTION_TYPE.REFUND:
    default:
      return t('Reports_Transaction_Type_Refund');
  }
};

export const formatCustomerContact = (contact) => {
  if (contact.includes('@')) return contact;
  return formatPhoneNumber(contact);
};

export const getPaymentType = (pmtType) => {
  switch (pmtType) {
    case PAYMENT_TYPE.CASH:
      return t('Reports_LoadhubPaymentType_Cash');
    case PAYMENT_TYPE.DEBIT_CARD:
      return t('Reports_LoadhubPaymentType_DebitCard');
    case PAYMENT_TYPE.CASH_AND_DEBIT_CARD:
      // Multiple payment types for one transaction: https://3.basecamp.com/3425901/buckets/28467858/todos/7472010082#__recording_7539248562
      return t('Reports_LoadhubPaymentType_CashDebit');
    default:
      return '';
  }
};

export const saveShoppersReportAsCSV = (
  filteredAndSortedRows,
  startDate,
  endDate,
  timezoneCode = null
) => {
  const timezone = timezoneCode || getUserTimezone();
  const headers = [
    t('Reports_ShoppersPaymentsReportCol_Date'),
    t('Reports_ShoppersPaymentsReportCol_TransNo'),
    t('Reports_ShoppersPaymentsReportCol_Amount'),
    t('Reports_ShoppersPaymentsReportCol_Fee'),
    t('Reports_ShoppersPaymentsReportCol_DealerCommission'),
    t('Reports_ShoppersPaymentsReportCol_ProdCode'),
    t('Reports_ShoppersPaymentsReportCol_ProdName'),
    t('Reports_ShoppersPaymentsReportCol_ProdClass'),
    t('Reports_ShoppersPaymentsReportCol_LegalName'),
    t('Reports_ShoppersPaymentsReportCol_UPC'),
    t('Reports_ShoppersPaymentsReportCol_RCNumber'),
    t('Reports_ShoppersPaymentsReportCol_WicketNumber'),
    t('Reports_ShoppersPaymentsReportCol_ClerkID'),
    t('Reports_ShoppersPaymentsReportCol_MerchantID'),
    t('Reports_ShoppersPaymentsReportCol_PaymentMethod'),
    t('Reports_ShoppersPaymentsReportCol_FintracFlag'),
    t('Reports_ShoppersPaymentsReportCol_FintracValue'),
    t('Reports_LoadhubPaymentsReportCol_Sequence'),
  ];
  const headerRow = `"${t('Reports_Shoppers_ExportCSV_CanadaPost_Title')} | ${startDate} - ${endDate} ${getTimezoneFullName(timezone)}"\n`;

  const csvContent = filteredAndSortedRows.reduce((acc, {
    Date,
    TransNo,
    Amount,
    Fee,
    DealerCommission,
    ProdCode,
    ProdName,
    ProdClass,
    LegalName,
    UPC,
    RCNumber,
    WicketNumber,
    ClerkID,
    MerchantID,
    PaymentMethod,
    FintracFlag,
    FintracValue,
    id,
  }) => {
    const values = [
      convertUTCToTimezoneDateTimeString(Date, timezone),
      TransNo,
      formatReportAmount(Amount),
      formatReportAmount(Fee),
      formatReportAmount(DealerCommission),
      ProdCode,
      ProdName,
      ProdClass,
      LegalName,
      UPC,
      RCNumber,
      WicketNumber,
      ClerkID,
      MerchantID,
      PaymentMethod,
      FintracFlag,
      FintracValue,
      id,
    ];

    return (`${acc}\n${values.map((v) => `"${v.replaceAll('"', '""')}"`).join(',')}`);
  }, `${headerRow}${headers.join(',')}`);
  const anchor = document.createElement('a');
  anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csvContent)}`;
  anchor.setAttribute('download', `${t('Reports_Shoppers_ExportCSV_CanadaPost_Title')} - ${startDate} - ${endDate} - ${t('Reports_Shoppers_ExportCSV_Transactions_Title')}.csv`);

  anchor.click();
};

export const saveLoadhubReportAsCSV = (
  filteredAndSortedRows,
  currentPartner,
  startDate,
  endDate,
  type,
  timezoneCode = null
) => {
  const timezone = timezoneCode || getUserTimezone();
  let values;
  let headers;
  let headerRow;

  if (type === REPORTS.PAYMENTS) {
    headers = [
      t('Reports_LoadhubPaymentsReportCol_Date'),
      t('Reports_LoadhubPaymentsReportCol_Amount'),
      t('Reports_PaymentsReportCol_CustomerRef'),
      t('Reports_LoadhubPaymentsReportCol_TransNo'),
      t('Reports_LoadhubPaymentsReportCol_LocName'),
      t('Reports_LoadhubPaymentsReportCol_PmtType'),
      t('Reports_LoadhubPaymentsReportCol_Sequence'),
    ];
    headerRow = `"${getPartnerName(currentPartner)} (${currentPartner.Partner_Seq}) | ${startDate.slice(0, -6)} - ${endDate.slice(0, -6)} ${getTimezoneFullName(timezone)}"\n`;
  } else { // Reconciliation report
    headers = [
      t('Reports_LoadhubReconciliationReportCol_PayoutRef'),
      t('Reports_LoadhubReconciliationReportCol_PayoutDate'),
      t('Reports_LoadhubReconciliationReportCol_Status'),
      t('Reports_LoadhubReconciliationReportCol_PaymentDate'),
      t('Reports_LoadhubReconciliationReportCol_Amount'),
      t('Reports_LoadhubReconciliationReportCol_TransNo'),
      t('Reports_LoadhubReconciliationReportCol_CustomerRef'),
      t('Reports_LoadhubPaymentsReportCol_Sequence'),
    ];

    headerRow = `"${getPartnerName(currentPartner)} (${currentPartner.Partner_Seq}) | ${startDate.slice(0, -6)} - ${endDate.slice(0, -6)}"\n`;
  }

  const csvContent = filteredAndSortedRows.reduce((acc, {
    Date,
    Amount,
    CustomerRef,
    TransNo,
    LocName,
    PmtType,
    PayoutRef,
    PayoutDate: pDate,
    Status,
    PaymentDate: pmDate,
    CustomerRef: cRef,
    id,
  }) => {
    if (type === REPORTS.PAYMENTS) {
      values = [
        convertUTCToTimezoneDateString(Date, timezone),
        formatReportAmount(Amount),
        CustomerRef,
        TransNo,
        LocName,
        getPaymentType(PmtType),
        id,
      ];
    } else { // Reconciliation report
      values = [
        PayoutRef,
        convertUTCToTimezoneDateString(pDate, timezone),
        getPayoutStatusLangValue(Status),
        convertUTCToTimezoneDateString(pmDate, timezone),
        formatReportAmount(Amount),
        TransNo,
        cRef,
        id,
      ];
    }

    return (`${acc}\n${values.map((v) => `"${v.replaceAll('"', '""')}"`).join(',')}`);
  }, `${headerRow}${headers.join(',')}`);
  const anchor = document.createElement('a');
  anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csvContent)}`;
  anchor.setAttribute('download', `${currentPartner.Partner_Seq} - ${startDate.slice(0, -6)} - ${endDate.slice(0, -6)} - ${t(type === REPORTS.PAYMENTS ? 'Reports_Payments_Title' : 'Reports_Reconciliation_Title')}.csv`);

  anchor.click();
};

export const saveReportAsCSV = (
  filteredAndSortedRows,
  currentPartner,
  startDate,
  endDate,
  type,
  timezoneCode = null
) => {
  const timezone = timezoneCode || getUserTimezone();
  const isDirectPlatform = getIntegrationPlatform() === 'Direct';
  let headerRow;
  let headers;
  let values;

  if (type === REPORTS.RECONCILIATION) { // Reconciliation Report
    headers = [
      t('Reports_ReconciliationReportCol_PayoutRef'),
      t('Reports_ReconciliationReportCol_PayoutDate'),
      t('Reports_ReconciliationReportCol_Status'),
      t('Reports_ReconciliationReportCol_Type'),
      t('Reports_ReconciliationReportCol_Amount'),
      t('Reports_ReconciliationReportCol_Fee'),
      t('Reports_ReconciliationReportCol_CreatedDate'),
      t('Reports_ReconciliationReportCol_DepositedDate'),
      t('Reports_ReconciliationReportCol_CustomerRef'),
      t('Reports_ExportCSV_PartnerPaySeq'),
    ];

    // Insert PaymentRef header at index 8 if Direct
    if (isDirectPlatform) headers.splice(8, 0, t('Reports_ReconciliationReportCol_PaymentRef'));
    headerRow = `"${getPartnerName(currentPartner)} (${currentPartner.Partner_Seq}) | ${startDate.slice(0, -6)} - ${endDate.slice(0, -6)}"\n`;
  } else { // Payments Report
    headers = [
      t('Reports_PaymentsReportCol_CreatedDate'),
      t('Reports_PaymentsReportCol_DepositedDate'),
      t('Reports_PaymentsReportCol_Status'),
      t('Reports_PaymentsReportCol_CustomerRef'),
      t('Reports_PaymentsReportCol_Amount'),
      t('Reports_PaymentsReportCol_Fee'),
      currentPartner.IntegrationPlatform === 'Shopify' ? t('Reports_PaymentsReportCol_CustomerContact') : t('Reports_PaymentsReportCol_CustomerEmail'),
      t('Reports_ExportCSV_PartnerPaySeq'),
    ];

    // Insert PaymentRef header at index 3 if Direct
    if (isDirectPlatform) headers.splice(3, 0, t('Reports_PaymentsReportCol_PaymentRef'));
    headerRow = `"${getPartnerName(currentPartner)} (${currentPartner.Partner_Seq}) | ${startDate} - ${endDate} ${getTimezoneFullName(timezone)}"\n`;
  }

  const csvContent = filteredAndSortedRows.reduce((acc, {
    PayoutRef,
    PayoutDate: pDate,
    Status,
    Type,
    Amount,
    Fee,
    CreatedDate: cDate,
    DepositedDate: dDate,
    PaymentRef,
    CustomerRef: cRef,
    CustomerContact: cContact,
    id,
  }) => {
    if (type === REPORTS.RECONCILIATION) { // Reconciliation Report
      values = [
        PayoutRef,
        convertUTCToTimezoneDateString(pDate, timezone),
        getPayoutStatusLangValue(Status),
        getTransactionTypeLangValue(Type),
        formatReportAmount(Amount),
        formatReportAmount(Fee),
        convertUTCToTimezoneDateString(cDate, timezone),
        convertUTCToTimezoneDateString(dDate, timezone),
        cRef,
        id,
      ];

      // Insert PaymentRef at index 8 if Direct
      if (isDirectPlatform) values.splice(8, 0, PaymentRef);
    } else { // Payments Report
      values = [
        convertUTCToTimezoneDateTimeString(cDate, timezone, true),
        convertUTCToTimezoneDateTimeString(dDate, timezone, true),
        getPaymentStatusLangValue(Status),
        cRef,
        formatReportAmount(Amount),
        formatReportAmount(Fee),
        formatCustomerContact(cContact),
        id,
      ];

      // Insert PaymentRef at index 3 if Direct
      if (isDirectPlatform) values.splice(3, 0, PaymentRef);
    }

    return (`${acc}\n${values.map((v) => `"${v.replaceAll('"', '""')}"`).join(',')}`);
  }, `${headerRow}${headers.join(',')}`);
  const anchor = document.createElement('a');
  anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csvContent)}`;
  if (type === REPORTS.RECONCILIATION) { // Reconciliation Report
    anchor.setAttribute('download', `${currentPartner.Partner_Seq} - ${startDate.slice(0, -6)} - ${endDate.slice(0, -6)} - ${t('Reports_Reconciliation_Title')}.csv`);
  } else { // Payments Report
    anchor.setAttribute('download', `${currentPartner.Partner_Seq} - ${startDate} - ${endDate} - ${t('Reports_Payments_Title')}.csv`);
  }
  anchor.click();
};

export const onSortColumnsChange = (cols, setSortColumns) => {
  setSortColumns(([prevSortColumn]) => {
    let newSortColumn;
    // react-data-grid sort direction always starts as ascending
    const clickedNewColumn = cols[0]?.direction === DIRECTION.ASCENDING;
    if (clickedNewColumn) {
      newSortColumn = { columnKey: cols[0].columnKey, direction: DIRECTION.DESCENDING };
    } else { // when the same column is clicked again, flip the sort direction
      newSortColumn = {
        columnKey: prevSortColumn.columnKey,
        direction: prevSortColumn.direction === DIRECTION.ASCENDING ?
          DIRECTION.DESCENDING : DIRECTION.ASCENDING,
      };
    }
    logAmpEvent(events.USER_CLICKED_SORT_COLUMN_BUTTON, {
      Column: newSortColumn.columnKey,
      Direction: newSortColumn.direction,
    });
    return [newSortColumn];
  });
};

export const convertDateTimeStringToDateString = (dateTimeStr) => {
  if (!dateTimeStr) return '';

  return dateTimeStr.slice(0, 10);
};

export const getDefaultDateRange = (reportType) => {
  let startDate = startOfToday();
  const endDate = endOfToday();

  if (reportType === REPORTS.PAYOUTS) {
    startDate = subDays(startDate, 6);
  } else if (reportType === REPORTS.PAYMENTS && isLoadhubUserGroup()) {
    startDate = subDays(startDate, 30);
  }
  return {
    startDate: parseDateTimeToString(startDate),
    endDate: parseDateTimeToString(endDate),
    timezone: getUserTimezone(),
  };
};

// Returns report width styling based on each column width clamped between 50% and 100% width.
export const getReportWidthStyle = (columnWidths, lang) => {
  const WIDTH_TO_REM_MULTIPLIER = 0.063; // 1rem = 16px, so 1 / 16 = 0.0625 (plus a small buffer)
  const widthsArray = Object.values(columnWidths[lang]);

  const totalWidths = widthsArray.reduce(((total, width) => total + width), 0);
  return `clamp(50%, ${totalWidths * WIDTH_TO_REM_MULTIPLIER}rem, 100%)`;
};

export const getColumnWidth = (columnWidths, columnKey, lang) => columnWidths[lang][columnKey];
